// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-597a16f7]{font-family:\"Open Sans\"}.font-header[data-v-597a16f7]{font-family:\"Hero\"}section[data-v-597a16f7] .section-inner{display:grid;grid-gap:20px;gap:20px;grid-template-columns:repeat(1,1fr)}@media screen and (min-width:600px){section[data-v-597a16f7] .section-inner{display:grid;grid-gap:80px;gap:80px;grid-template-columns:repeat(2,1fr)}}@media screen and (min-width:pad){section[data-v-597a16f7] .section-inner{display:grid;grid-gap:80px;gap:80px;grid-template-columns:repeat(2,1fr)}}section[data-v-597a16f7] .section-inner .container:first-child{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}section[data-v-597a16f7] .section-inner .container:last-child{display:flex;flex-wrap:wrap;justify-content:center;align-items:center}section[data-v-597a16f7] .section-inner .container-inner{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}section[data-v-597a16f7] .section-inner .container img{max-width:100%}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
