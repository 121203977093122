<template>
    <Section
        class="banner"
        :class="[slice.slice_label, { 'with-author': hasAuthor }]"
        :width="bannerWidth"
    >
        <div class="container">
            <h1 v-if="$validateText(slice.primary.h1)">
                {{ $prismic.asText(slice.primary.h1) }}
            </h1>
            <p v-if="$validateText(slice.primary.teaser)">
                {{ $prismic.asText(slice.primary.teaser) }}
            </p>
        </div>
        <author-and-editor
            :document="document" 
        />
    </Section>
</template>

<script>
import AuthorAndEditor from '@/components/AuthorAndEditor.vue';
export default {
    components: { AuthorAndEditor },
    props: {
        slice: {
            type: Object,
            required: true
        },
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        bannerWidth() {
            return this.slice.primary.width ?? 'lg';
        },
        hasAuthor() {
            return this.document.data.post_author.id ?? null;
        }
    }
};
</script>

<style lang="scss" scoped>
    .container {
        @include grid(1, 20px);
        @include device(pad) {
            @include grid(1, 30px);
        }

        h1 {
            word-break: break-word;
        }
    }
</style>
