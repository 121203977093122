<template>
    <li class="lenders-list__item">
        <prismic-image
            :img="lender.data.logo"
            w="150"
        />
        <div class="data">
            <table>
                <tr>
                    <th>{{ $translate('loan_amount', 'Belopp') }}</th>
                    <td>{{ model.amountString }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('interest', 'Ränta') }}</th>
                    <td>{{ model.interestString }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('loan_duration', 'Löptid') }}</th>
                    <td>{{ model.getDurationString() }}</td>
                </tr>
            </table>
            <table>
                <tr>
                    <th>{{ $translate('credit_check', 'Kreditupplysning') }}</th>
                    <td class="capitalize">{{ model.creditCheck }}</td>
                </tr>
                <tr v-if="model.isBroker">
                    <th>{{ $translate('connected_banks', 'Anslutna banker') }}</th>
                    <td>{{ model.connectedBanks }}</td>
                </tr>
                <tr v-else>
                    <th>{{ $translate('without_uc', 'Utan UC') }}</th>
                    <td v-if="!model.takesUC"><i class="fa-regular fa-check-circle" /></td>
                    <td v-else><i class="fa-regular fa-times-circle" /></td>
                </tr>
                <tr>
                    <th>{{ $translate('payment_remarks', 'Bet. anmärkningar') }}</th>
                    <td v-if="model.acceptsPaymentRemarks">
                        <i class="fa-regular fa-check-circle" />
                    </td>
                    <td v-else><i class="fa-regular fa-times-circle" /></td>
                </tr>
            </table>
        </div>
        <ButtonTarget
            class="button"
            :link="lender"
            background="orange"
            size="md"
        />
    </li>
</template>
<script>
export default {
    props: {
        lender: {
            type: Object,
            required: true,
        },
    },
    computed: {
        model() {
            return new this.$models.Loan(this.lender);
        },
    },
};
</script>
<style lang="scss" scoped>
.capitalize {
    text-transform: capitalize;
}

.lenders-list__item {
    @include border-radius(20px);
    background: $white;
    box-shadow: $bxs;
    @include spacing(padding, 4);
    @include flex(start, start);
    transition: 0.3s ease-in-out;
    @include border(all, 1px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include device(pad) {
        @include spacing(padding, 8);
    }

    .button {
        margin-top: 20px;
    }

    @media screen and (min-width: 900px) {
        flex-direction: row;

        .button {
            margin-top: 0;
        }
    }

    .data {
        width: 100%;

        @media screen and (min-width: 900px) {
            width: 60%;
            display: flex;
            justify-content: space-between;
        }

        table {
            width: 100%;

            @media screen and (min-width: 900px) {
                width: 48%;
            }

            tr {
                @include border(bottom, 1px);

                td,
                th {
                    padding: 15px 5px;

                    @include device(pad) {
                        padding: 15px;
                    }
                }

                td {
                    font-weight: bold;
                    text-align: right;
                }
            }
        }
    }
}
</style>
