<template>
    <Section
        class="banner"
        width="lg"
    >
        <div class="container">
            <div style="display: flex;">
                <h1>{{ $prismic.asText(slice.primary.h1) }}</h1>
                <div class="container img-container-mobile">
                    <prismic-image
                        :img="slice.primary.image"
                        :alt="$prismic.asText(slice.primary.h1)"
                        w="170"
                        :lazy="false"
                    />
                </div>
            </div>
            <p>{{ $prismic.asText(slice.primary.teaser) }}</p>

            <div class="container-buttons">
                <Button
                    v-scroll-to="{
                        el: '#cards-header',
                        offset: -100
                    }"
                    :text="slice.primary.page_link_1_text"
                    background="green"
                    size="lg"
                />

                <button-nuxt
                    :link="slice.primary.page_link_2"
                    :text="slice.primary.page_link_2_text"
                    background="green-border"
                    size="lg"
                />
            </div>
        </div>

        <div class="container img-container-desktop">
            <prismic-image
                :img="slice.primary.image"
                :alt="$prismic.asText(slice.primary.h1)"
                w="610"
                :lazy="false"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        },
        document: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        width: 1300px;
        @include flex;

        .img-container-mobile{
            display: none;
        }

        @media screen and (max-width: 600px) {
            .img-container-desktop{
            display: none;
            }
            .img-container-mobile{
                display: block !important;
                width: 100%;
                min-width: 100px;
                max-width: 170px;
                height: auto;
            }
            h1{
                margin-right: 1rem;
            }
        }

        .container {
            img {
                max-width: 100%;
                height: auto;
            }

            @include device(pad) {
                flex: 1;
            }

            &:first-child {
                @include grid(1, 30px);
                @include device(mobile) { padding-bottom: 40px; }
                @include device(pad) { padding-right: 80px; }
            }

            &:last-child {}


            &-buttons {
                @media screen and (max-width: 1200px) {
                    @include grid(1, 20px);
                }

                @media screen and (min-width: 1200px) {
                    @include grid(2, 20px);
                }
            }
        }
    }
}
</style>
