<template>
    <Section :class="slice.slice_label" width="lg">
        <div v-if="showAllCards" class="sorting">
            <button
                class="btn btn-sm"
                :class="activeSort === 'recommended' ? 'btn-green' : 'btn-green-border'"
                @click="activeSort = 'recommended'"
            >
                {{ $translate('sort_recommended', 'Rekommenderade först') }}
            </button>
            <button
                class="btn btn-sm"
                :class="activeSort === 'alphabetical' ? 'btn-green' : 'btn-green-border'"
                @click="activeSort = 'alphabetical'"
            >
                {{ $translate('sort_alphabetical', 'Alfabetiskt') }}
            </button>
        </div>

        <section-header
            id="cards-header"
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <ul class="card-list">
            <card
                v-for="card in cardsToShow"
                :key="card.id"
                :card="card"
                :category-data-key="categoryDataKey"
            />
        </ul>
        <trust-us />
    </Section>
</template>

<script>
import Card from './list-item/ListItemCard.vue';
import TrustUs from '@/components/TrustUs.vue';
import { orderBy, partition } from 'lodash';
export default {
    components: { Card, TrustUs },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            activeSort: 'recommended',
        };
    },
    computed: {
        selectedCards() {
            let cards = [];
            if (this.slice.primary.list_preset.id) {
                cards = this.$store.state.lists[this.slice.primary.list_preset.id].data.cards;
                // return map(list, 'card');
            }
            else {
                cards = this.slice.items;
            }

            cards = cards
                .map(iterator => iterator.card)
                .filter(card => card.id && card.type !== 'broken_type');

            return cards;
        },
        cards() {
            if (this.selectedCards.length) {
                return this.selectedCards;
            }

            return this.$store.state.cards;
        },

        cardsToShow() {
            if (this.showAllCards) {
                return this.sortedCards;
            }

            return this.cards
                .map(card => this.$store.state.cards[card.id])
                .filter(card => !card.data.lists_disabled);
        },
        showAllCards() {
            return !this.selectedCards.length;
        }, 
        sortedCards() {
            if (this.activeSort === 'recommended') {
                const [partnerCards, otherCards] = partition(
                    this.cards,
                    card =>
                        card.data.application_type !== 'no_partnership' &&
                        card.data.application_type !== null
                );
                return [...this.$getOrderedList(partnerCards), ...this.$getOrderedList(otherCards)];
            }

            return orderBy(this.cards, 'data.title', 'asc');
        },
        categoryDataKey() {
            return this.slice.primary.category_data_key;
        },
    },
};
</script>

<style lang="scss" scoped>
section {
    .card-list {
        width: 100%;
        @include grid(1, 30px);
    }

    .sorting {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        row-gap: 0.5rem;
        margin-bottom: 1rem;
        @include font-size(16px);
        font-weight: bold;
        font-family: $fontHeader;
        button {
            border-width: 1px;
        }
    }
}
</style>
