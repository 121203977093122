<template>
    <div>
        <Slices
            :slices="document.data.body"
            :document="document"
        />
        <AuthorBox :document="document" />
    </div>
</template>

<script>
import AuthorBox from '@/components/AuthorBox.vue';
import Slices from '@/components/slices/Slices.vue';
export default {
    components: { 
        AuthorBox,
        Slices 
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    }
};
</script>
