// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-79025443]{font-family:\"Open Sans\"}.font-header[data-v-79025443]{font-family:\"Hero\"}section[data-v-79025443] .section-inner .container{text-align:center;display:grid;grid-gap:30px;gap:30px;grid-template-columns:repeat(1,1fr);grid-gap:20px;gap:20px}@media screen and (min-width:600px){section[data-v-79025443] .section-inner .container{grid-gap:30px;gap:30px}}@media screen and (min-width:pad){section[data-v-79025443] .section-inner .container{grid-gap:30px;gap:30px}}section[data-v-79025443] .section-inner .container h1{word-break:break-word}section[data-v-79025443] .section-inner .container.left{text-align:left}section[data-v-79025443] .section-inner .container.right{text-align:right}section[data-v-79025443] .section-inner .container.center{text-align:center}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
