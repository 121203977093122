// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-1c4a3029]{font-family:\"Open Sans\"}.font-header[data-v-1c4a3029]{font-family:\"Hero\"}section .card-list[data-v-1c4a3029]{width:100%;display:grid;grid-gap:30px;gap:30px;grid-template-columns:repeat(1,1fr)}section .sorting[data-v-1c4a3029]{display:flex;flex-wrap:wrap;grid-gap:1rem;gap:1rem;grid-row-gap:.5rem;row-gap:.5rem;margin-bottom:1rem;font-size:16px;line-height:22px;font-weight:700;font-family:\"Hero\"}section .sorting button[data-v-1c4a3029]{border-width:1px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
